import React from 'react';
import "./song-tile.css";
import SongTileIcon from "./SongTileIcon";

export default class SongTile extends React.Component {
    render() {
        return <div className={'song-tile-container'}>
            <div className={'icon-container'}>
                <SongTileIcon votes={this.props.votes}/>
                {/*Kind of a hack but gets the job done quick, no regrets!*/}
                <p className={'vote-count'} style={{
                    color: (() => {
                        if (this.props.votes === 0) return 'rgb(134, 116, 245)';
                        if (this.props.votes < 0) return 'rgb(228, 88, 88)';
                        if (this.props.votes > 0) return 'rgb(41, 222, 113)';
                    })()
                }}>{Math.abs(this.props.votes)}</p>
            </div>
            <div className={'verbiage-container'}>
                <p className={'tile-song'}>{this.props.songTitle}</p>
                <p className={'tile-artist'}>{this.props.artistTitle}</p>
            </div>
        </div>
    }
}
