import * as queryString from 'query-string'
import RandomCodeService from "./RandomCodeService";
import {STORAGE} from "../enums/STORAGE";
import {SUBSCRIBE} from "../enums/SUBSCRIBE";
import * as PubSub from "pubsub-js";

const {
    REACT_APP_APP_URL: APP_URL,
    REACT_APP_SPOTIFY_API_URL: SPOTIFY_API_URL,
    REACT_APP_SPOTIFY_API_REDIRECT_URL: SPOTIFY_API_REDIRECT_URL,
    REACT_APP_SPOTIFY_CLIENT_ID: SPOTIFY_CLIENT_ID,
    REACT_APP_SPOTIFY_AUTHORIZE_URL: SPOTIFY_AUTHORIZE_URL,
    REACT_APP_SPOTIFY_WEB_PLAYER_NAME: SPOTIFY_WEB_PLAYER_NAME,
    REACT_APP_SPOTIFY_WEB_PLAYER_SDK_URL: SPOTIFY_WEB_PLAYER_SDK_URL,
} = process.env;

let player;
let lastKnownCurrentTime = 0;
let isPaused = true;

const SCOPE =
    'user-read-private user-read-email streaming user-modify-playback-state user-read-playback-state'

class Spotify {
    constructor() {
        this.accessToken = ''
    }

    login() {
        window.location = this.buildUrl(SPOTIFY_AUTHORIZE_URL, {
            response_type: 'token',
            client_id: SPOTIFY_CLIENT_ID,
            redirect_uri: SPOTIFY_API_REDIRECT_URL,
            scope: SCOPE,
            state: RandomCodeService.generateRandomString(16),
        })
    }

    async checkLoginStatus() {
        if (!this.accessToken) {
            const params = queryString.parse(window.location.hash)
            const access_token =
                params.access_token || localStorage.getItem('accessToken')
            if (access_token) {
                try {
                    const response = await fetch(
                        this.buildUrl(`${SPOTIFY_API_URL}/me`, {access_token})
                    )
                    if (response.ok) {
                        this.accessToken = access_token
                        localStorage.setItem('accessToken', access_token)
                        return response.json()
                    } else {
                        this.logoutUser()
                    }
                } catch (error) {
                    console.error(error)
                    this.logoutUser()
                }
            }
        }
    }

    logout() {
        localStorage.removeItem('accessToken')
        this.accessToken = null
        window.location = APP_URL
    }

    getTopPlaylist() {
    }

    addTrackToQueue() {
    }

    static sendSearchQuery() {
    }

    static receiveSearchResponse() {
    }

    static receiveTrackQueue() {
    }

    sendSearchResponse() {
    }

    receiveSearchQuery() {
    }

    sendTrackQueue() {
    }

    receiveTrackVote() {
    }

    async webPlayerInit() {

        window.onSpotifyWebPlaybackSDKReady = () => {
            player = new window.Spotify.Player({
                name: SPOTIFY_WEB_PLAYER_NAME,
                getOAuthToken: cb => {
                    cb(window.localStorage.getItem(STORAGE.SPOTIFY_AUTH))
                },
            })
            player.addListener('initialization_error', ({message}) => {
                console.error(message)
            })
            player.addListener('authentication_error', ({message}) => {
                console.error(message)
            })
            player.addListener('account_error', ({message}) => {
                console.error(message)
            })
            player.addListener('playback_error', ({message}) => {
                console.error(message)
            })
            player.addListener('player_state_changed', state => {
                // console.log(state)
                this.currentTrack = state.track_window.current_track
            })
            player.addListener('ready', async ({device_id}) => {
                console.log('Ready with Device ID', device_id)
                await this.put(
                    this.buildUrl(`${SPOTIFY_API_URL}/me/player`, {
                        access_token: window.localStorage.getItem(STORAGE.SPOTIFY_AUTH),
                    }),
                    {
                        device_ids: [device_id],
                    }
                )
            })
            player.addListener('not_ready', ({device_id}) => {
                console.log('Device ID has gone offline', device_id)
            })

            player.addListener('player_state_changed', ({
                                                            position,
                                                            duration,
                                                            track_window: {current_track}
                                                        }) => {

                if (position === duration) {
                    console.log('song over')
                }

            });

            player.connect();

            //Listen for time change.

            setInterval(() => {
                player.getCurrentState().then((state) => {

                    let currentTime = state?.position ?? 0;

                    if (currentTime !== lastKnownCurrentTime) {
                        lastKnownCurrentTime = currentTime;
                        PubSub.publish(SUBSCRIBE.CURRENT_TIME_CHANGED, lastKnownCurrentTime);
                    }

                    if(isPaused !== state?.paused) {
                        isPaused = state?.paused;
                        if(isPaused) PubSub.publish(SUBSCRIBE.TRACK_ENDED, true);
                    }

                });
            }, 100);

        };
        await this.loadScript({
            defer: true,
            id: 'spotify-player',
            source: SPOTIFY_WEB_PLAYER_SDK_URL,
        })
    }

    buildUrl(url, params) {
        return `${url}?${new URLSearchParams(params).toString()}`
    }

    loadScript(attributes) {
        if (!attributes || !attributes.source) {
            throw new Error('Invalid attributes')
        }
        return new Promise((resolve, reject) => {
            const {async, defer, id, source} = {
                async: false,
                defer: false,
                source: '',
                ...attributes,
            }
            const scriptTag = document.getElementById('spotify-player')
            if (!scriptTag) {
                const script = document.createElement('script')
                script.id = id || ''
                script.type = 'text/javascript'
                script.async = async
                script.defer = defer
                script.src = source
                script.onload = () => resolve(undefined)
                script.onerror = error =>
                    reject(`createScript: ${error.message}`)
                document.head.appendChild(script)
            } else {
                resolve()
            }
        })
    }

    put(url, body) {
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
    }

    convertSpotifyItemToGeneric(item) {
        return {
            title: item?.name,
            artist: item?.artists[0]?.name,
            albumThumbnail: item?.album?.images[2]?.url,
            id: item?.id,
            duration: item?.duration_ms,
            upvotes: 0,
            downvotes: 0,
            uri: item.uri
        }
    }

    async search(songTitle) {
        const response = await fetch(`${SPOTIFY_API_URL}/search?${new URLSearchParams({
            access_token: window.localStorage.getItem(STORAGE.SPOTIFY_AUTH),
            q: songTitle,
            type: 'artist,track',
            limit: 10,
        }).toString()}`);
        return response.json().then((response) => {
            return response?.tracks?.items?.map(this.convertSpotifyItemToGeneric);
        })
    }

    async get(songId) {
        const response = await fetch(`${SPOTIFY_API_URL}/tracks/${songId}?${new URLSearchParams({
            access_token: window.localStorage.getItem(STORAGE.SPOTIFY_AUTH),
        }).toString()}`);
        return response.json().then(this.convertSpotifyItemToGeneric)
    }

    async play(uri) {
        await fetch(`${SPOTIFY_API_URL}/me/player/play?${new URLSearchParams({
            access_token: window.localStorage.getItem(STORAGE.SPOTIFY_AUTH),
        }).toString()}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uris: [uri],
            }),
        })
    }

    onSongEnd(cb) {
        return PubSub.subscribe(SUBSCRIBE.TRACK_ENDED, cb)
    }

    onCurrentTimeChange(cb) {
        return PubSub.subscribe(SUBSCRIBE.CURRENT_TIME_CHANGED, cb);
    }

}

export default Spotify
