import React, {useEffect} from 'react'
import Header from '../../components/shared/Header/Header'
import DemoImages from '../../components/home/DemoImages/DemoImages'
import QAEntry from '../../components/home/QAEntry/QAEntry'
import Footer from '../../components/home/Footer/Footer'
import Spotify from '../../services/Spotify'
import { withContext } from '../../context'
import './home.css'
import {useLocation, useNavigate} from "@reach/router";
import {STORAGE} from "../../enums/STORAGE";
import RandomCodeService from "../../services/RandomCodeService";

function Home({ setApiService, setUser }) {

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(function () {
		if(location.hash) {
			console.log(location.hash)
			console.log(new URLSearchParams( '?' + location.hash.slice(1)).toString())
			window.localStorage.setItem(STORAGE.SPOTIFY_AUTH, new URLSearchParams('?' + location.hash.slice(1)).get('access_token'));
			navigate(`stream/${RandomCodeService.generateRandomString(6)}`)
		}
	}, []);

	const login = async apiService => {
		const user = await apiService.login();
		if (user) {
			setUser(user);
			setApiService(apiService)
		}
	};

	const heroButtons = view => {
		return (
			<div className={`hero-btn-container hero-btn-container-${view}`}>
				<div
					className='hero-btn hero-btn-start-party'
					onClick={() => login(new Spotify())}
				>
					Start a party
				</div>
				<div className='hero-btn hero-btn-join-party'>Join a party</div>
			</div>
		)
	}

	return (
		<div className='home-container'>
			<Header width={212} height={30} />
			<div className='hero-content'>
				<div className='hero-description'>
					<h1>
						<span className='text-purple'>Everyone</span> can be the
						DJ.
					</h1>
					<p>
						Wish to have your guest dancing and singing along with
						music that they actually love? With QRJukebox, host can
						let every guest decide what to play next. Connect to
						Spodify to easily set up a room, run it full screen or
						on a TV, and guests can contribute to the playlist by
						scanning a QR code or enter the room code on desktop.
					</p>
					{heroButtons('desktop')}
				</div>
				<div>
					<DemoImages />
				</div>
				{heroButtons('mobile')}
			</div>
			<div className='qa-content'>
				<QAEntry header='What is this?'>
					The QR Jukebox is a web app that is meant to be broadcast to
					a TV (via AirPlay or Chromecast) or run full screen on your
					computer. It lets your guests contribute to the playlist
					from their own devices by scanning a QR code. They can also
					upvote or downvote songs added by others, and react to the
					current track through fun emoji reactions that appear on
					screen.
				</QAEntry>
				<QAEntry header='How does it work?'>
					As a host, create a room via the “Create a room” button on
					this page, link to your Spotify account and set up an
					initial playlist. Broadcast it to a TV via AirPlay or
					Chromecast, or run the page full screen on your computer.
					And woolah! The room is ready to use. As a guest, scan the
					QR code on the screen to vote or add songs on your mobile
					device. Alternatively you can enter the unique room short
					code under the QR code to join on desktop via the “Join a
					room via code” button on this page.
				</QAEntry>
				<QAEntry header='Is it free to use?'>
					We don’t make any profit out of this project. The only
					requirement is that the host have a Spotify subscription to
					set up a room. It’s completely FREE for guests to join and
					vote for songs.
				</QAEntry>
				<QAEntry header='Who made this?'>
					QR Jukebox is brought to you by Cody Mikol, Kostas Nasis,
					Zach Kearns, YingYing Zhang, and Shu Li.
				</QAEntry>
				<QAEntry header='Questions? Suggestions?'>
					Email us at{' '}
					<a href='mailto:qrjukebox@justshipped.io'>
						qrjukebox@justshipped.io
					</a>
					. We’d love to hear from you.
				</QAEntry>
			</div>
			<Footer />
		</div>
	)
}

export default withContext(Home)
