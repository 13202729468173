import React from 'react'

function ClearSearchIcon() {
	return (
		<svg viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.93912 5L9.8055 8.86638C10.0648 9.12571 10.0648 9.54617 9.8055 9.8055C9.54617 10.0648 9.12571 10.0648 8.86638 9.8055L5 5.93912L1.13362 9.8055C0.874286 10.0648 0.453828 10.0648 0.194498 9.8055C-0.0648326 9.54617 -0.0648326 9.12571 0.194498 8.86638L4.06088 5L0.194498 1.13362C-0.0648326 0.874286 -0.0648326 0.453828 0.194498 0.194498C0.453828 -0.0648326 0.874286 -0.0648326 1.13362 0.194498L5 4.06088L8.86638 0.194498C9.12571 -0.0648326 9.54617 -0.0648326 9.8055 0.194498C10.0648 0.453828 10.0648 0.874286 9.8055 1.13362L5.93912 5Z'
				fill='#8674F5'
			/>
		</svg>
	)
}

export default ClearSearchIcon
