import React from 'react'

function Room() {
	return (
		<div className='client-header-room-container'>
			<div className='client-header-room-text'>
				Room{' '}
				<span className='client-header-room-text client-header-room-text-bold'>
					CH3425
				</span>
			</div>
			<div className='client-header-room-button'>Exit Room</div>
		</div>
	)
}

export default Room
