import React from 'react'

function DownvoteIcon() {
	return (
		<svg viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.0752 0L0.749016 -8.01063e-07L9.9121 14.8019L19.0752 0Z'
				fill='#E45858'
			/>
		</svg>
	)
}

export default DownvoteIcon
