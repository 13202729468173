import React, {useEffect, useState} from 'react'
import Header from '../../components/client/Header/Header'
import SearchInput from '../../components/client/SearchInput/SearchInput'
import SongQueueHeader from '../../components/client/SongQueueHeader/SongQueueHeader'
import SongQueue from '../../components/client/SongQueue/SongQueue'
import EmojiSender from '../../components/client/EmojiSender/EmojiSender'
import {emitEvent, subscribe} from "../../services/JukeSocket";
import {EVENTS} from "../../enums/EVENTS";
import './client.css'
import AddSongs from "../../components/client/AddSongs/AddSongs";
import QueuedSongs from "../../components/client/QueuedSongs/QueuedSongs";

function Client(props) {

    const [searchText, setSearchText] = useState('');

    //todo(mikol): what is q / setQ ??

    //todo(mikol): the song selector and song queue are too tightly coupled, its making it hard to reason about.
    //refactor these into separate components.

    useEffect(function () {
        subscribe(props.roomCode)
    }, []);

    return (
        <div className='client-container'>

            <div className='client-wrapper-top-padding'>
                <Header/>
                <SearchInput searchText={searchText} setSearchText={setSearchText}/>
                <SongQueueHeader searchText={searchText} setSearchText={setSearchText}/>
            </div>

            {!!searchText ? <AddSongs onAdd={() => setSearchText(null)} /> : <QueuedSongs />}

            <EmojiSender send={() => emitEvent(EVENTS.EMOJI, {})}/>

        </div>
    )

}

export default Client
