import React from 'react'

function UpvoteIcon() {
	return (
		<svg viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.749023 15.2717L19.0752 15.2717L9.91211 -8.81386e-05L0.749023 15.2717Z'
				fill={'#29DE71'}
			/>
		</svg>
	)
}

export default UpvoteIcon
