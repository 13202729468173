import React from 'react'
import { ClearSearchIcon } from '../../shared/Icons'
import './song-queue-header.css'

function SongQueueHeader({ searchText, setSearchText }) {
	const clearSearch = () => setSearchText('')

	return (
		<div className='client-sqh-container'>
			<span className='client-sqh-text client-sqh-text-bold'>
				Up Next
			</span>
			{searchText && (
				<div className='client-sqh-clear-search' onClick={clearSearch}>
					<ClearSearchIcon />
					<span className='client-sqh-text client-sqh-text-purple'>
						Clear
					</span>
				</div>
			)}
			{!searchText && (
				<span className='client-sqh-text client-sqh-text-white'>
					Tap songs to vote
				</span>
			)}
		</div>
	)
}

export default SongQueueHeader
