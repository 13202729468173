import React from 'react';
import "./record.css";

export default class Record extends React.Component {

    constructor(props) {
        super(props);
        this.state = {pos: 1};
    }

    render() {
        return <div className={'record-container'}>
            {/*I made the Record SVG 28% as that is its percentage of the width in figma*/}
            <svg id={'record-carousel-resize-on'} className={(() => {
                // Position can be 1-5 and refers to the positioning on the carousel
                return 'record pos-' + this.props.position;
            })()} width="28%" viewBox="0 0 323 323" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="161.5" cy="161.5" r="161.5" fill="black"/>
                <mask id="path-2-inside-1" fill="white">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M161.5 318.911C248.436 318.911 318.911 248.436 318.911 161.5C318.911 74.5641 248.436 4.08861 161.5 4.08861C74.5641 4.08861 4.08862 74.5641 4.08862 161.5C4.08862 248.436 74.5641 318.911 161.5 318.911ZM161.5 278.025C225.855 278.025 278.025 225.855 278.025 161.5C278.025 97.1448 225.855 44.9747 161.5 44.9747C97.1449 44.9747 44.9747 97.1448 44.9747 161.5C44.9747 225.855 97.1449 278.025 161.5 278.025Z"/>
                </mask>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M161.5 318.911C248.436 318.911 318.911 248.436 318.911 161.5C318.911 74.5641 248.436 4.08861 161.5 4.08861C74.5641 4.08861 4.08862 74.5641 4.08862 161.5C4.08862 248.436 74.5641 318.911 161.5 318.911ZM161.5 278.025C225.855 278.025 278.025 225.855 278.025 161.5C278.025 97.1448 225.855 44.9747 161.5 44.9747C97.1449 44.9747 44.9747 97.1448 44.9747 161.5C44.9747 225.855 97.1449 278.025 161.5 278.025Z"
                      fill="url(#paint0_linear)"/>
                <path
                    d="M317.911 161.5C317.911 247.884 247.884 317.911 161.5 317.911V319.911C248.988 319.911 319.911 248.988 319.911 161.5H317.911ZM161.5 5.08861C247.884 5.08861 317.911 75.1164 317.911 161.5H319.911C319.911 74.0118 248.988 3.08861 161.5 3.08861V5.08861ZM5.08862 161.5C5.08862 75.1164 75.1164 5.08861 161.5 5.08861V3.08861C74.0118 3.08861 3.08862 74.0118 3.08862 161.5H5.08862ZM161.5 317.911C75.1164 317.911 5.08862 247.884 5.08862 161.5H3.08862C3.08862 248.988 74.0118 319.911 161.5 319.911V317.911ZM277.025 161.5C277.025 225.303 225.303 277.025 161.5 277.025V279.025C226.407 279.025 279.025 226.407 279.025 161.5H277.025ZM161.5 45.9747C225.303 45.9747 277.025 97.6971 277.025 161.5H279.025C279.025 96.5926 226.407 43.9747 161.5 43.9747V45.9747ZM45.9747 161.5C45.9747 97.6971 97.6971 45.9747 161.5 45.9747V43.9747C96.5926 43.9747 43.9747 96.5926 43.9747 161.5H45.9747ZM161.5 277.025C97.6971 277.025 45.9747 225.303 45.9747 161.5H43.9747C43.9747 226.407 96.5926 279.025 161.5 279.025V277.025Z"
                    fill="#424242" mask="url(#path-2-inside-1)"/>
                <circle cx="161.5" cy="161.5" r="148.734" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="152.823" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="144.646" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="140.557" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="136.468" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="132.38" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="128.291" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="124.203" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="120.114" stroke="#424242"/>
                <circle cx="161.5" cy="161.5" r="136.468" stroke="#424242"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="34.7532" y1="73.5949" x2="294.891" y2="249.405"
                                    gradientUnits="userSpaceOnUse">
                        <stop stopColor="#454545"/>
                        <stop offset="0.489583"/>
                        <stop offset="1" stopColor="#454545"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    }
}