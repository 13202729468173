import React, {useRef, useState} from 'react'
import { HeartIcon } from '../../shared/Icons'
import './emoji-sender.css'
import {useHandleClickOutside, useWindowEvent} from "../../../services/UseService";
import MusicIcon from "../../shared/Icons/MusicIcon";
import GuitarIcon from "../../shared/Icons/GuitarIcon";
import PooIcon from "../../shared/Icons/PooIcon";
import ClapIcon from "../../shared/Icons/ClapIcon";

function EmojiSender() {

	const [isOpen, setOpen] = useState(false);
	const wrapperRef = useRef(null);
	useHandleClickOutside(wrapperRef, () => { if(isOpen) setOpen(false) });

	return (
		<div ref={wrapperRef} onClick={() => { if (!isOpen) setOpen(true) }} className='emoji-sender-container'>
			<div className={'emoji-icon ' + (isOpen ? 'active' : '')}>
				{isOpen}
				<MusicIcon/>
				<GuitarIcon/>
				<HeartIcon active={isOpen}/>
				<PooIcon/>
				<ClapIcon/>
			</div>
		</div>
	)
}

export default EmojiSender
