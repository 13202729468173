import React from 'react';

export default class NeutralIcon extends React.Component {
    render() {
        return <svg style={{marginBottom: '9px'}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7.70073H7.70073V0H12.2993V7.70073H20V12.2993H12.2993V20H7.70073V12.2993H0V7.70073Z"
                  fill="#8674F5"/>
        </svg>
    }
}
