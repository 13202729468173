import React from 'react';
import "./song-queue.css";
import SongTile from "../SongTile/SongTile";
import {withContext} from "../../../context";

function SongQueue(props) {
    return (
        <div className={'song-queue-container'}>
            <div className={'top-queue-header'}>
                <p className={'up-next'}>Up Next</p>
            </div>
            {props.trackQueue?.map(({title, artist, id}) => <SongTile artistTitle={artist} songTitle={title} votes={0} key={id}/>)}
        </div>
    )
}

export default withContext(SongQueue)
