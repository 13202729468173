import {useEffect} from "react";
import {onEvent} from "./JukeSocket";

export const useWindowEvent = (event, callback) => {
    useEffect(() => {
        window.addEventListener(event, callback);
        return () => window.removeEventListener(event, callback);
    }, [event, callback]);
};

export const useHandleClickOutside = (ref, cb) => {
    useWindowEvent("mousedown", (event) => {
        if (ref.current && !ref.current.contains(event.target)) cb()
    });
};

export const useEventHook = (event, callback) => {
    useEffect(() => {
        onEvent(event, callback)
    },[])
}
