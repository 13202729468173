import React, {useEffect, useState} from 'react';
import "./currently-playing.css";
import {DownvoteIcon, UpvoteIcon} from "../../shared/Icons";
import {withContext} from "../../../context";
import MusicApi from "../../../services/MusicApi";

function CurrentlyPlaying(props) {

    function msToTime(s) {
        // Pad to 2 or 3 digits, default is 2
        var pad = (n, z = 2) => ('00' + n).slice(-z);
        let hrs = pad(s/3.6e6|0);
        return (hrs === '00' ? '' : hrs + ':') + pad((s%3.6e6)/6e4 | 0) + ':' + pad((s%6e4)/1000|0);
    }

    const [currentTime, setCurrentTime] = useState(0);

    useEffect(function () {
        if(props.currentTrack) MusicApi.play(props.currentTrack.uri)
    }, [props.currentTrack]);

    useEffect(function () {
        let changeSubcription = MusicApi.onCurrentTimeChange((msg, time) => {
            setCurrentTime(time);
        });
        return () => changeSubcription.unsubscribe();
    }, []);

    //TODO: Add an empty state whn no tracks selected like in figma

    return (
        <div className={'currently-playing-container'}>
            <h3 className={'currently-playing-song'}>{props.currentTrack?.title}</h3>
            <h4 className={'currently-playing-artist'}>{props.currentTrack?.artist}</h4>
            <div className={'currently-playing-progress-container'}>
                <div style={{transform: 'scaleX(' + (currentTime / (props.currentTrack?.duration || 0)) + ')'}} className={'currently-playing-progress'}/>
            </div>
            <div className={'currently-playing-timing-container'}>
                <div className={'currently-playing-time'}>{msToTime(currentTime)}</div>
                <div className={'currently-playing-time'}>{msToTime(props.currentTrack?.duration)}</div>
            </div>
            <div className={'currently-playing-vote-container'}>
                <div className={'currently-playing-votes'}>
                    <div className={'currently-playing-icon-container'}>
                        <UpvoteIcon/>
                    </div>
                    <span className={'currently-playing-count upvote'}>{props.currentTrack?.upvotes}</span>
                </div>
                <div className={'currently-playing-votes'}>
                    <div className={'currently-playing-icon-container'}>
                        <DownvoteIcon/>
                    </div>
                    <span className={'currently-playing-count downvote'}>{props.currentTrack?.downvotes}</span>
                </div>
            </div>
        </div>
    )
}

export default withContext(CurrentlyPlaying)
