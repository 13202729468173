import React from 'react'
import './demo-images.css'

export default class DemoImages extends React.Component {
	render() {
		return (
			<div className={'demo-images-container'}>
				<img
					className={'demo-images'}
					src='./assets/hero-img.png'
					alt='hero'
				/>
			</div>
		)
	}
}
