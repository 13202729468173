import React, { useState } from 'react'
import './App.css'
import { Router } from '@reach/router'
import Host from './views/Host/Host'
import Home from './views/Home/Home'
import Client from './views/Client/Client'
import { Provider } from './context'

function App() {
	const [user, setUser] = useState(null)
	const [trackQueue, setTrackQueue] = useState([])
	const [currentTrack, setCurrentTrack] = useState(null)
	const [apiService, setApiService] = useState(null)
	const [roomCode, setRoomCode] = useState(null);

	const context = () => ({
		user,
		setUser,
		trackQueue,
		setTrackQueue,
		currentTrack,
		setCurrentTrack,
		apiService,
		setApiService,
		roomCode,
		setRoomCode
	});

	return (
		<div className='App'>
			<Provider value={context()}>
				<Router>
					<Home path='/' />
					<Host path='stream/:roomCode' />
					<Client path='room/:roomCode' />
				</Router>
			</Provider>
		</div>
	)
}

export default App
