import React from 'react'

function SearchIcon() {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.91666 15.8333C3.54441 15.8333 0 12.2889 0 7.91666C0 3.54441 3.54441 0 7.91666 0C12.2889 0 15.8333 3.54441 15.8333 7.91666C15.8333 9.84443 15.1443 11.6113 13.9991 12.9843L18.8882 18.2495C19.2796 18.6711 19.2552 19.3302 18.8336 19.7216C18.4121 20.1131 17.753 20.0887 17.3615 19.6671L12.4668 14.3959C11.1795 15.3016 9.6102 15.8333 7.91666 15.8333ZM7.91683 13.75C11.1385 13.75 13.7502 11.1383 13.7502 7.91664C13.7502 4.69498 11.1385 2.08331 7.91683 2.08331C4.69517 2.08331 2.0835 4.69498 2.0835 7.91664C2.0835 11.1383 4.69517 13.75 7.91683 13.75Z'
				fill='#9A9A9A'
			/>
		</svg>
	)
}

export default SearchIcon
