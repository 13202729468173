import React from 'react';

export default class UpvoteIcon extends React.Component {
    render() {
        return <svg style={{marginBottom: '11px'}} width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 16.6667L20 16.6667L10 2.05437e-05L0 16.6667Z"
                  fill="#29DE71"/>
        </svg>
    }
}
