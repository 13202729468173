import React, {useEffect} from 'react';
import Header from "../../components/shared/Header/Header";
import RecordCarousel from "../../components/desktop/RecordCarousel/RecordCarousel";
import CurrentlyPlaying from "../../components/desktop/CurrentlyPlaying/CurrentlyPlaying";
import EmojiEmitter from "../../components/desktop/EmojiEmitter/EmojiEmitter";
import QRSidePanel from "../../components/desktop/QRSidePanel/QRSidePanel";
import SongQueue from "../../components/shared/SongQueue/SongQueue";
import "./host.css";
import {withContext} from "../../context";
import {emitEvent, onEvent, subscribe} from "../../services/JukeSocket";
import {EVENTS} from "../../enums/EVENTS";
import MusicApi from "../../services/MusicApi";
import Spotify from "../../services/Spotify";

class Host extends React.Component {

    componentDidMount() {
        this.props.setRoomCode(this.props.roomCode);
        MusicApi.configure(new Spotify());

        subscribe(this.props.roomCode).then(() => {

            // We use this as a health check and to keep users up to date on latest added tracks
            setInterval(() => {
                if(this.props.trackQueue[0]?.id) {
                    emitEvent(EVENTS.LATEST_QUEUED, {latest: this.props.trackQueue[0]?.id})
                }
            }, 500)

            onEvent(EVENTS.REQUEST_QUEUE, () => {
                console.info('Client is requesting queue', this.props.trackQueue)
                emitEvent(EVENTS.RETURN_QUEUE, ({queue: this.props.trackQueue}))
            })

            onEvent(EVENTS.SEARCH_SONG, (message) => {
                console.info(`Client searching for`, message)
                MusicApi.search(message.data.title).then(function (songs) {
                    emitEvent(EVENTS.RETURN_SONG, {songs: songs || []})
                })
            });

            onEvent(EVENTS.REQUEST_SONG, (songRequest) => {
                MusicApi.get(songRequest.data.songId).then((response) => {
                    this.props.setTrackQueue(this.props.trackQueue.concat(response));
                    if (this.props.currentTrack === null) this.props.setCurrentTrack(response);
                });
            });

            MusicApi.onSongEnd(() => {
                let newTrackQueue = this.props.trackQueue.length ? this.props.trackQueue.slice(1) : [];
                this.props.setTrackQueue(newTrackQueue);
                if (newTrackQueue.length > 0) {
                    this.props.setCurrentTrack(newTrackQueue[0])
                } else {
                    this.props.setCurrentTrack(null);
                }
            });

        })
    }

    render() {
        return <div className={'desktop-container'}>
            <div className={'side-panel'}>
                <SongQueue/>
            </div>
            <div className={'center-content'}>
                <div className={'center-header-wrapper'}>
                    <Header/>
                </div>
                <RecordCarousel/>
                <CurrentlyPlaying/>
                <EmojiEmitter/>
            </div>
            <div className={'side-panel'}>
                <QRSidePanel/>
            </div>
        </div>
    }

}

export default withContext(Host)

