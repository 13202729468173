import React from 'react'
import './footer.css'

export default class Footere extends React.Component {
	render() {
		return (
			<div className={'footer-container'}>
				<p>
					QR Jukebox is a{' '}
					<a
						href='http://justshipped.io/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Just Shipped
					</a>{' '}
					project
				</p>
				<p>Copyright © 2020. All rights reserved</p>
			</div>
		)
	}
}
