import React from 'react';
import "./emoji.css";

export default class Emoji extends React.Component {

    render() {
        return <div className={'emoji-container'} style={{left: this.props.xOffset + '%'}}>
            <div className={'emoji'} style={{animationDelay: '-' + this.props.animationTimeXOffset + 'ms'}}>{this.props.icon}</div>
        </div>
    }
}
