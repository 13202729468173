
let client = null;

const MusicApi = {
    search: search,
    play: play,
    get: get,
    configure: configure,
    onCurrentTimeChange: onCurrentTimeChange,
    onSongEnd: onSongEnd,
};

function configure(vendorMusicApi) {
    client = vendorMusicApi;
    client.webPlayerInit();
}

async function search(songTitle) {
    return client.search(songTitle);
}

async function play(uri) {
    return client.play(uri)
}

async function get(songId) {
    return client.get(songId)
}

function onCurrentTimeChange(cb) {
    return client.onCurrentTimeChange(cb);
}

function onSongEnd(cb) {
   return client.onSongEnd(cb)
}

// async function getCurrentTimeMs() {
//     return client?.getCurrentTimeMs() || 0
// }

export default MusicApi;