import React from 'react';
import "./qa-entry.css";

export default class QAEntry extends React.Component {
    render() {
        return <div className={'qa-entry-container'}>
            <h2>{this.props.header}</h2>
            <p>
                {this.props.children}
            </p>
        </div>
    }
}
