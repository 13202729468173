import React, { useState } from 'react'
import './song-tile.css'

function SongTile({ title, artist, albumThumbnail }) {
	const [showVoteButtons, setShowVoteButtons] = useState(false)

	const songTileClick = () => setShowVoteButtons(setShowVoteButtons => !setShowVoteButtons)

	return (
		<div className='client-song-tile-wrapper' onClick={songTileClick}>
			<div className='client-song-tile'>
				{/*{!q && <Votes votes={votes} />}*/}
				<img
					className='client-song-album-thumbnail'
					src={albumThumbnail}
					alt={artist}
				/>
				<div className='client-song-info'>
					<div className='client-song-title'>{title}</div>
					<div className='client-song-artist'>{artist}</div>
				</div>
			</div>
			{/*<div className='client-song-tile-action'>*/}
			{/*	{!q && showVoteButtons && <VoteButtons />}*/}
			{/*</div>*/}
		</div>
	)
}

export default SongTile
