import React from 'react';
import "./emoji-emitter.css";
import Emoji from "./Emoji";
import {onEvent} from "../../../services/JukeSocket";
import {EVENTS} from "../../../enums/EVENTS";
import {EMOJI} from "../../../enums/EMOJI";

//Thought about doing a dict, but because we would have to use square bracket notation and the type is an untrusted
//string they could perform an object type sink injection attack :(
function getEmoji(type) {
    switch (type) {
        case EMOJI.NOTE:
            return '🎵';
        case EMOJI.GUITAR:
            return '🎸';
        case EMOJI.HEART:
            return '❤️';
        case EMOJI.POO:
            return '💩';
        case EMOJI.CLAP:
            return '👏';
        default:
            return '🎵'
    }
}

export default class EmojiEmitter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {emojis: [], sequenceId: 0}
    }

    componentDidMount() {
        onEvent(EVENTS.EMOJI, (message) => {
            this.sendEmoji(message.data.type)
        });
    }

    sendEmoji(type) {

        let nextSeq = this.state.sequenceId + 1;

        this.state.emojis.push({
            id: nextSeq,
            //Random for now, but this will be determined by the network event
            icon: getEmoji(type),
            //Give a little randomness as to when the xPos animation is offset by so everything won't follow the same
            // pattern
            animationTimeXOffset: Math.floor(Math.random() * 1000) + 1,
            //Where horizontally our emoji will begin its path from (So far anywhere between 2% and 88% looks good, but
            // this is kindof hacky, can tweak later...
            xOffset: Math.floor(Math.random() * 88) + 2,
        });

        this.setState({
            sequenceId: nextSeq,
            emojis: this.state.emojis
        });

        // Cleanup
        setTimeout(() => {
            this.setState({
                emojis: this.state.emojis.filter((e) => e.id !== nextSeq)
            })
        }, 10000)

    }

    render() {
        return <div className={'emoji-emitter-container'}>
            <div className={'inner-relative-emoji-wrapper'}>
                {this.state.emojis.map((o, i) => <Emoji
                    icon={o.icon}
                    xOffset={o.xOffset}
                    animationTimeXOffset={o.animationTimeXOffset}
                    key={o.id}/>)}
            </div>
        </div>
    }
}
