import React, {useEffect} from 'react'
import './search-input.css'
import {SearchIcon} from '../../../components/shared/Icons'
import {emitEvent} from "../../../services/JukeSocket";
import debounce from 'lodash/debounce'
import {EVENTS} from "../../../enums/EVENTS";

const query = debounce(function(searchText) { emitEvent(EVENTS.SEARCH_SONG, {title: searchText}) }, 500);

function SearchInput({searchText, setSearchText}) {

    useEffect(() => {
        query(searchText)
    }, [searchText]);

    return (
        <div className='search-input-container'>
            <div className='search-icon'>
                <SearchIcon/>
            </div>
            <input
                value={searchText}
                className='search-input'
                placeholder='Search song to add'
                onChange={(event) =>  setSearchText(event.target.value)}
            />
        </div>
    )
}

export default SearchInput
