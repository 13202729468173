import React, {useEffect, useRef, useState} from "react";
import {emitEvent, onEvent} from "../../../services/JukeSocket";
import {EVENTS} from "../../../enums/EVENTS";
import SongTile from "../SongTile/SongTile";
import {useEventHook} from "../../../services/UseService";

function QueuedSongs() {

    const [queue, setQueue] = useState([])
    const [latest, setLatest] = useState(undefined)

    useEventHook(EVENTS.LATEST_QUEUED, (message) => setLatest(message?.data?.latest))

    useEventHook(EVENTS.RETURN_QUEUE, (message) => setQueue(message.data.queue))

    useEffect(() => {
        if (!latest) {
            setQueue([])
        } else {
            emitEvent(EVENTS.REQUEST_QUEUE, {});
        }
    }, [latest])

    return (
        <div>
            {queue.map((song) => {
                return <>
                    <div className='client-divider'/>
                    <SongTile {...song}/>
                </>
            })}
            {Boolean(queue.length) && <div className='client-divider'/>}
        </div>
    )

}

export default QueuedSongs;
