import React, {useEffect, useState} from "react";
import {emitEvent, onEvent} from "../../../services/JukeSocket";
import {EVENTS} from "../../../enums/EVENTS";
import SongTile from "../SongTile/SongTile";
import AddButton from "../SongTile/AddButton";

function AddSongs({ onAdd }) {

    const [tracks, setTracks] = useState([]);

    const addSong = (id) => {
        emitEvent(EVENTS.REQUEST_SONG, {songId: id});
        onAdd();
    };

    useEffect(function () {
        onEvent(EVENTS.RETURN_SONG, (message) => {
            console.log("RETURN", message)
            setTracks(message.data.songs || [])
        })
    }, []);

    return (
        <div className='client-song-queue-container'>
            {tracks && tracks.map(track => (
                <div key={track.id}>
                    <div className='client-divider'/>
                    <div className='client-list-item-inner'>
                        <SongTile {...track}/>
                        <AddButton click={() => addSong(track.id)}/>
                    </div>
                </div>
            ))}
            {Boolean(tracks.length) && <div className='client-divider'/>}
        </div>
    )

}

export default AddSongs
