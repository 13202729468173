import {BEND, BendSocket} from "./BendSocket";

let isLoggedIn = false;
let callbacks = [];
let events = [];
let channel;
let socket;

const awaitLogin = new Promise((resolve) => {
    socket = new BendSocket({
        onOpen() {
            socket.logInToAppWithCredentials(BEND.APP_KEY, 'pusher', 'pusher')
        },
        onMessage(message) {
            if (message.id === 'login') {
                isLoggedIn = true;
                resolve();
            }
            callbacks.forEach((fn) => fn(message));
            events.forEach((e) => {
                if(message.event === e.eventName) e.callback(message)
            })
        }
    });
});

function getChannelById(channelId) {
    return `party-${channelId}`
}

export async function subscribe(channelId) {
    await awaitLogin;
    channel = getChannelById(channelId);
    socket.subscribe(channel);
}

export function unsubscribe(channelId) {
    channel = null;
    socket.unsubscribe(getChannelById(channelId))
}

export function onMessage(callback) {
    callbacks.push(callback);
}

export function onEvent(eventName, callback) {
    events.push({eventName, callback})
}

export async function emitEvent(event, data) {
    await awaitLogin;
    socket.push(channel, event, data)
}

