import React, {useEffect, useState} from 'react'
import SongTile from '../SongTile/SongTile'
import './song-queue.css'
import {onEvent} from "../../../services/JukeSocket";
import {EVENTS} from "../../../enums/EVENTS";

function SongQueue({ q }) {

	const [tracks, setTracks] = useState([]);

	useEffect(function () {
		onEvent(EVENTS.RETURN_SONG, (message) => {
			console.log("RETURN", message)
			setTracks(message.data.songs || [])
		})
	}, []);

	return (
		<div className='client-song-queue-container'>
			{tracks &&
				tracks
					.sort((song1, song2) => song2.votes - song1.votes)
					.map(track => (
						<div key={track.id}>
							<div className='client-divider'/>
							<SongTile {...track} setTracks={setTracks} q={q} />
						</div>
					))}
			{Boolean(tracks.length) && <div className='client-divider'/>}
		</div>
	)
}

export default SongQueue
