import React from 'react';
import UpvoteIcon from "./UpvoteIcon";
import DownvoteIcon from "./DownvoteIcon";
import NeutralIcon from "./NeutralIcon";

export default class SongTileIcon extends React.Component {
    render() {
        if (this.props.votes > 0) return <UpvoteIcon/>;
        if (this.props.votes < 0) return <DownvoteIcon/>;
        if (this.props.votes === 0) return <NeutralIcon/>;
    }
}
