import React from 'react';
import "./record-carousel.css";
import Record from "./Record";

export default class RecordCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zero: 0,
            one: 1,
            two: 2,
            three: 3,
            four: 4,
            five: 5,
            six: 6,
            minHeight: 0,
        }
    }

    componentDidMount() {
        this.calcHeight();
        window.addEventListener('resize', this.calcHeight.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calcHeight.bind(this));
    }

    //TODO: would love a pure CSS version of this, but after spending too much time on it, this will work for now...
    calcHeight() {
        this.setState({minHeight: document.getElementById('record-carousel-resize-on').clientHeight});
    }

    render() {
        return <div style={{minHeight: this.state.minHeight}} className={'carousel-container'} onClick={() => {
            ['zero', 'one', 'two', 'three', 'four', 'five', 'six'].forEach((key) => {
                var newState = {};
                newState[key] = (this.state[key] === 6 ? 0 : this.state[key] + 1);
                this.setState(newState);
            })
        }}>
            <Record position={this.state.zero}/>
            <Record position={this.state.one}/>
            <Record position={this.state.two}/>
            <Record position={this.state.three}/>
            <Record position={this.state.four}/>
            <Record position={this.state.five}/>
            <Record position={this.state.six}/>
        </div>
    }
}
