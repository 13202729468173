import React from 'react';
import "./qrside-panel.css";
import {withContext} from "../../../context";

const QRCode = require('qrcode.react');

const {
    REACT_APP_QR_PHONE_REDIRECT_PREFIX: REACT_APP_QR_PHONE_REDIRECT_PREFIX,
} = process.env;

const URL_PREFIX = `${REACT_APP_QR_PHONE_REDIRECT_PREFIX}/room/`;

function QRSidePanel(props) {

    return (
        <div className={'qrside-panel-container'}>
            <div>
                <h1 className={'qrside-header1'}>
                    You decide
                </h1>
                <h2 className={'qrside-header2'}>
                    what to play
                </h2>
            </div>
            <div className={'qrside-qrcontainer'}>
                <div className={'qrside-qrcode'} style={{padding: '20px'}}>
                    <QRCode size={260} value={URL_PREFIX + props.roomCode ?? ''}/>
                </div>
                <p className={'qrside-qrtext'}>
                    Desktop code: <strong>{props.roomCode?.toUpperCase()}</strong>
                </p>
            </div>
            <p>
                Scan the QR code above with your phone or enter the code on desktop at <a
                href={'https://qrjukebox.com'}>qrjukebox.com</a> to contribute to the playlist
            </p>

            <p className={'qrside-footer'}>
                Powered by <a href={'https://qrjukebox.com'}>qrjukebox.com</a>
            </p>
        </div>
    )

}

export default withContext(QRSidePanel)
