import React from 'react'
import { JukeIcon } from '../../shared/Icons'
import Room from './Room'
import './header.css'

function Header() {
	return (
		<div className='client-header'>
			<JukeIcon />
			<Room />
		</div>
	)
}

export default Header
